<template>
  <div class="summary-container mt-2">
    <h4
      id="title-summary-usdt"
      data-testid="title-summary-usdt"
      class="title-summary"
    >
      {{ $t('addFundPage.creditCardPayment.textSummary') }}
    </h4>
    <hr class="hr-custom">
    <div class="d-flex align-items-center justify-content-between mb-1">
      <div
        id="title-amount-usdt"
        data-testid="title-amount-usdt"
        class="font-14 text-placeholder"
      >
        {{ $t('balance.textAmount') }}
      </div>
      <strong
        v-if="amountValidate"
        id="usdt-current-amount"
        data-testid="usdt-current-amount"
      >
        {{ localStringFormat(amount) }} USD
      </strong>
      <strong v-else>
        --
      </strong>
    </div>
    <div class="d-flex align-items-center justify-content-between mb-1">
      <div
        id="title-usdt-method"
        data-testid="title-usdt-method"
        class="font-14 text-placeholder"
      >
        {{ $t('addFundPage.creditCardPayment.textPaymentMethod') }}
      </div>
      <div
        v-if="amount"
        id="usdt-method"
        data-testid="usdt-method"
      >
        <img
          :src="getBrandIcon(`USDT`)"
          width="18px"
          alt="coin"
          style="margin-right: 6px"
        >
        <strong id="payment-method">USDT</strong>
      </div>
      <strong v-else>
        --
      </strong>
    </div>
    <div class="d-flex align-items-center justify-content-between mb-1">
      <div
        id="title-coin-type"
        data-testid="title-coin-type"
        class="font-14 text-placeholder"
      >
        {{ $t('payment.USDT.labelCoinType') }}
      </div>
      <div
        v-if="amount"
        id="usdt-coin-type"
        data-testid="usdt-coin-type"
      >
        <img
          :src="getBrandIcon(paymentMethod.icon)"
          width="18px"
          alt="coin"
          style="margin-right: 6px"
        >
        <strong id="coin-type">{{ paymentMethod.value }}</strong>
      </div>
      <strong v-else>
        --
      </strong>
    </div>

    <div
      v-if="percentFee"
      class="d-flex align-items-center justify-content-between mb-1"
    >
      <div
        id="title-service-fee-usdt"
        data-testid="title-service-fee-usdt"
        class="font-14 text-placeholder"
      >
        {{ $t('payment.balanceTransactionsTable.textServiceCharge') }}
        <small
          id="percent-service-usdt-fee"
          data-testid="percent-service-usdt-fee"
          class="font-small-1"
          style="margin-right: 6px"
        >
          {{ `(${percentFeeTxt})` }}
        </small>
      </div>
      <div>
        <div
          v-if="amountValidate"
          id="usdt-service-charge"
          data-testid="usdt-service-charge"
          class="d-flex align-items-center"
        >
          <strong
            id="usdt-current-service-charge"
            data-testid="usdt-current-service-charge"
            class="text-primary"
          >{{ serviceCharge }} USD</strong>
        </div>
        <strong v-else>
          --
        </strong>
      </div>
    </div>

    <div
      v-if="methodFee"
      class="d-flex align-items-center justify-content-between mb-1"
    >
      <div
        id="title-usdt-fee"
        data-testid="title-usdt-fee"
        class="font-14 text-placeholder"
      >
        {{ $t('payment.paypal.textPaymentFee', {payment:"USDT"}) }}
        <small
          id="percent-usdt-fee"
          data-testid="percent-usdt-fee"
          class="font-small-1"
          style="margin-right: 6px"
        >
          {{ `(${methodFeeTxt})` }}
        </small>
      </div>
      <div>
        <div
          v-if="amountValidate"
          id="usdt-fee"
          data-testid="usdt-fee"
          class="d-flex align-items-center"
        >
          <strong
            id="current-usdt-fee"
            data-testid="current-usdt-fee"
            class="text-primary"
          >{{ USDTFee }} USD</strong>
        </div>
        <strong v-else>
          --
        </strong>
      </div>
    </div>

    <div
      v-if="taxRate"
      class="d-flex align-items-center justify-content-between mb-1"
    >
      <div
        id="title-tax-fee-usdt"
        data-testid="title-tax-fee-usdt"
        class="font-14 text-placeholder"
      >
        {{ $t('payment.balanceTransactionsTable.textTax') }}
        <small
          id="percent-tax-usdt-fee"
          data-testid="percent-tax-usdt-fee"
          class="font-small-1"
          style="margin-right: 6px"
        >
          {{ `(${percentTaxTxt})` }}
        </small>
      </div>
      <div>
        <div
          v-if="amountValidate"
          class="d-flex align-items-center"
        >
          <strong
            id="current-usdt-tax-fee"
            data-testid="current-usdt-tax-fee"
            class="text-primary"
          >{{ tax }} USD</strong>
        </div>
        <strong v-else>
          --
        </strong>
      </div>
    </div>

    <div class="d-flex align-items-center justify-content-between mb-1">
      <div
        id="title-transfer-network"
        data-testid="title-transfer-network"
        class="font-14 text-placeholder"
      >
        {{ $t('payment.USDT.labelTransferNetwork') }}
      </div>
      <div class="transfer">
        <div
          v-if="amountValidate"
          class="d-flex align-items-center"
        >
          <strong
            id="usdt-transfer-network"
            data-testid="usdt-transfer-network"
            class="theme-text-primary"
          >{{ transfer.value }}</strong>
        </div>
        <strong v-else>
          --
        </strong>
      </div>
    </div>

    <div
      v-if="hasAMId"
      class="d-flex align-items-center justify-content-between"
    >
      <div
        id="title-tip-usdt"
        data-testid="title-tip-usdt"
        class="font-14 text-placeholder"
      >
        Tip
        <small
          v-if="currentTip > 0"
          id="percent-tip-usdt-fee"
          data-testid="percent-tip-usdt-fee"
          class="font-small-1"
          style="margin-right: 6px"
        >
          {{ `(${percentTipTxt})` }}
        </small>
      </div>
      <div>
        <div
          v-if="amountValidate"
          class="d-flex align-items-center"
        >
          <strong
            id="current-usdt-tip-amount"
            data-testid="current-usdt-tip-amount"
            class="text-primary"
          >{{ amountTipTxt }} USD</strong>
        </div>
        <strong v-else>
          --
        </strong>
      </div>
    </div>

    <hr class="hr-custom">
    <div class="d-flex align-items-center justify-content-between pb-3">
      <div
        id="title-total-amount-usdt"
        data-testid="title-total-amount-usdt"
        class="font-24 text-placeholder"
      >
        {{ $t('addFundPage.creditCardPayment.textTotalAmount') }}
      </div>
      <strong
        v-if="amountValidate"
        id="current-usdt-total-amount"
        data-testid="current-usdt-total-amount"
        class="font-24 total-amount"
      >
        {{ totalAmount }} USD
      </strong>
      <strong v-else>
        --
      </strong>
    </div>
  </div>
</template>

<script>
import paymentMixin from '@/mixins/paymentMixin'
import generalConfigsMixin from '@/mixins/generalConfigsMixin'
import numberFormatMixin from '@/mixins/numberFormatMixin'

// plugins
import { createNamespacedHelpers } from 'vuex'

const { mapGetters: mapGettersAuth } = createNamespacedHelpers('auth')

export default {

  mixins: [paymentMixin, generalConfigsMixin, numberFormatMixin],

  props: {
    amount: {
      type: Number,
      required: true,
    },
    paymentMethod: {
      type: Object,
      required: true,
    },
    transfer: {
      type: Object,
      required: true,
    },

    amountTip: {
      type: Number,
      required: true,
    },
    currentTip: {
      type: Number,
      required: true,
    },
  },

  computed: {
    ...mapGettersAuth(['user']),

    isInTrial() {
      return this.user?.isInTrial
    },

    percentFee() {
      return this.user?.data?.plans?.transferFee?.USDT || 0
    },

    percentFeeTxt() {
      return `${(this.percentFee * 100).toFixed(2)}%`
    },

    percentTaxTxt() {
      return `${(this.taxRate * 100).toFixed(2)}%`
    },

    methodFee() {
      return this.generalConfigs?.paymentGateFee?.USDTGate || 0
    },

    methodFeeTxt() {
      return `${(this.methodFee * 100).toFixed(2)}%`
    },

    USDTFee() {
      return this.amountFormat(this.methodFee * this.amount).toLocaleString('en-US')
    },

    tax() {
      // return parseFloat(this.truncDigits(this.taxRate * this.amount)).toLocaleString('en-US')
      return this.amountFormat(this.taxRate * this.amount).toLocaleString('en-US')
    },

    percentTipTxt() {
      return `${(this.currentTip).toFixed(2)}%`
    },

    amountTipTxt() {
      return this.amountFormat(this.amountTip).toLocaleString('en-US')
    },

    totalAmount() {
      const fee = this.amountFormat(this.percentFee * this.amount)
      const tax = this.amountFormat(this.taxRate * this.amount)
      const methodFee = this.amountFormat(this.methodFee * this.amount)
      // return parseFloat(this.truncDigits(this.amount + fee + tax)).toLocaleString('en-US')
      return (this.amount + fee + tax + methodFee + this.amountTip).toLocaleString('en-US')
    },

    serviceCharge() {
      // return parseFloat(this.truncDigits(this.percentFee * this.amount)).toLocaleString('en-US')
      return this.amountFormat(this.percentFee * this.amount).toLocaleString('en-US')
    },

    amountValidate() {
      return !!(this.amount >= this.minTopUp && this.amount <= this.maxAmount)
    },

    maxAmount() {
      return 100000
    },
  },
}
</script>

<style lang="scss" scoped>
.summary-container {
  padding: 20px;
  background-color: #F5F5F7;
  // border: 1px solid rgba(22, 33, 62, 0.1);
  border-radius: 16px;

  .title-summary{
    margin-bottom: 21px;
  }
}
  .transfer {
    max-width: 125px;
  }

.total-amount{
  color: #27a27a;
}
</style>
