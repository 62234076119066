<template>
  <div class="summary-container">
    <h4
      id="title-summary"
      data-testid="title-summary"
      class="title-summary"
    >
      {{ $t('addFundPage.creditCardPayment.textSummary') }}
    </h4>
    <hr class="hr-custom">
    <div class="d-flex align-items-center justify-content-between mb-1">
      <div
        id="title-amount"
        data-testid="title-amount"
        class="font-14 text-placeholder"
      >
        {{ $t('balance.textAmount') }}
      </div>
      <strong
        v-if="amountValidate"
        id="amount-credit-card"
        data-testid="amount-credit-card"
      >
        {{ localStringFormat(amount) }} USD
      </strong>
      <strong v-else>
        --
      </strong>
    </div>
    <div class="d-flex align-items-center justify-content-between mb-1">
      <div
        id="title-payment-method"
        data-testid="title-payment-method"
        class="font-14 text-placeholder"
      >
        {{ $t('addFundPage.creditCardPayment.textPaymentMethod') }}
      </div>
      <div>
        <img
          :src="creditCardLogo"
          height="16px"
          alt="credit"
          style="margin-right: 5px"
        >
        <strong
          id="credit-card-method"
          data-testid="credit-card-method"
          class="font-14 text-primary"
        >
          {{ cardName }}
        </strong>
      </div>
    </div>
    <div
      v-if="percentFee"
      class="d-flex align-items-center justify-content-between mb-1"
    >
      <div
        id="title-service-charge"
        data-testid="title-service-charge"
        class="font-14 text-placeholder"
      >
        {{ $t('payment.balanceTransactionsTable.textServiceCharge') }}
        <small
          id="percent-service-charge-credit-card-fee"
          data-testid="percent-service-charge-credit-card-fee"
          class="font-small-1"
          style="margin-right: 6px"
        >
          {{ `(${percentFeeTxt})` }}
        </small>
      </div>
      <div>
        <div
          v-if="amountValidate"
          class="d-flex align-items-center"
        >
          <strong
            id="service-charge-credit-card"
            data-testid="service-charge-credit-card"
            class="text-primary"
          >{{ serviceCharge }} USD</strong>
        </div>
        <strong v-else>
          --
        </strong>
      </div>
    </div>

    <div
      v-if="methodFee"
      class="d-flex align-items-center justify-content-between mb-1"
    >
      <div
        id="title-method-fee"
        data-testid="title-method-fee"
        class="font-14 text-placeholder"
      >
        {{ $t('payment.creditCard.titleCreditCardFee') }}
        <small
          id="percent-credit-card-fee"
          data-testid="percent-credit-card-fee"
          class="font-small-1"
          style="margin-right: 6px"
        >
          {{ `(${methodFeeTxt})` }}
        </small>
      </div>
      <div>
        <div
          v-if="amountValidate"
          class="d-flex align-items-center"
        >
          <strong
            id="credit-card-fee"
            data-testid="credit-card-fee"
            class="text-primary"
          >{{ creditCardFee }} USD</strong>
        </div>
        <strong v-else>
          --
        </strong>
      </div>
    </div>

    <div
      v-if="taxRate"
      class="d-flex align-items-center justify-content-between mb-1"
    >
      <div
        id="title-tax-fee"
        data-testid="title-tax-fee"
        class="font-14 text-placeholder"
      >
        {{ $t('payment.balanceTransactionsTable.textTax') }}
        <small
          id="percent-tax-credit-card-fee"
          data-testid="percent-tax-credit-card-fee"
          class="font-small-1"
          style="margin-right: 6px"
        >
          {{ `(${percentTaxTxt})` }}
        </small>
      </div>
      <div>
        <div
          v-if="amountValidate"
          class="d-flex align-items-center"
        >
          <strong
            id="tax-fee-credit-card"
            data-testid="tax-fee-credit-card"
            class="text-primary"
          >{{ tax }} USD</strong>
        </div>
        <strong v-else>
          --
        </strong>
      </div>
    </div>

    <div
      v-if="hasAMId"
      id="title-tip-amount"
      data-testid="title-tip-amount"
      class="d-flex align-items-center justify-content-between"
    >
      <div class="font-14 text-placeholder">
        {{ $t('payment.balanceTransactionsTable.textTip') }}
        <small
          v-if="currentTip > 0"
          id="percent-tip-credit-card-fee"
          data-testid="percent-tip-credit-card-fee"
          class="font-small-1"
          style="margin-right: 6px"
        >
          {{ `(${percentTipTxt})` }}
        </small>
      </div>
      <div>
        <div
          v-if="amountValidate"
          class="d-flex align-items-center"
        >
          <strong
            id="tip-amount-credit-card"
            data-testid="tip-amount-credit-card"
            class="text-primary"
          >{{ amountTipTxt }} USD</strong>
        </div>
        <strong v-else>
          --
        </strong>
      </div>
    </div>

    <hr class="hr-custom">
    <div class="d-flex align-items-center justify-content-between pb-3">
      <div
        id="title-total-amount"
        data-testid="title-total-amount"
        class="font-24 text-placeholder"
      >
        {{ $t('addFundPage.creditCardPayment.textTotalAmount') }}
      </div>
      <strong
        v-if="amountValidate"
        id="total-amount-credit-card"
        data-testid="total-amount-credit-card"
        class="font-24 total-amount"
      >
        {{ totalAmount }} USD
      </strong>
      <strong v-else>
        --
      </strong>
    </div>
  </div>
</template>
<script>
import { createNamespacedHelpers } from 'vuex'
import _find from 'lodash/find'

// Mixins
import paymentMixin from '@/mixins/paymentMixin'
import numberFormatMixin from '@/mixins/numberFormatMixin'
import generalConfigsMixin from '@/mixins/generalConfigsMixin'
import { toastification } from '@core/mixins/toast'

const { mapGetters } = createNamespacedHelpers('payment')
const { mapGetters: mapGettersAuth } = createNamespacedHelpers('auth')

export default {

  mixins: [paymentMixin, toastification, numberFormatMixin, generalConfigsMixin],

  props: {
    amount: {
      type: Number,
      required: true,
    },
    amountTip: {
      type: Number,
      required: true,
    },
    currentTip: {
      type: Number,
      required: true,
    },
  },

  computed: {
    ...mapGetters(['listCard', 'loading']),
    ...mapGettersAuth(['user']),

    creditCardLogo() {
      const cardSelected = _find(this.listCard, { default: true })
      if (cardSelected) {
        return this.getCreditCardLogo(cardSelected.brand)
      }
      return ''
    },

    cardName() {
      const cardSelected = _find(this.listCard, { default: true })
      if (cardSelected) {
        return this.getCreditCardName(cardSelected.brand)
      }
      return ''
    },

    percentFee() {
      return this.user?.data?.plans?.transferFee?.creditCard || 0
    },

    percentFeeTxt() {
      return `${(this.percentFee * 100).toFixed(2)}%`
    },

    methodFee() {
      return this.generalConfigs?.paymentGateFee?.creditCardGate || 0
    },

    methodFeeTxt() {
      return `${(this.methodFee * 100).toFixed(2)}%`
    },

    creditCardFee() {
      return this.amountFormat(this.methodFee * this.amount)
        .toLocaleString('en-US')
    },

    serviceCharge() {
      // return parseFloat(this.truncDigits(this.percentFee * this.amount)).toLocaleString('en-US')
      return this.amountFormat(this.percentFee * this.amount)
        .toLocaleString('en-US')
    },

    percentTaxTxt() {
      return `${(this.taxRate * 100).toFixed(2)}%`
    },

    percentTipTxt() {
      return `${(this.currentTip).toFixed(2)}%`
    },

    amountTipTxt() {
      return this.amountFormat(this.amountTip).toLocaleString('en-US')
    },

    tax() {
      // return parseFloat(this.truncDigits(this.taxRate * this.amount)).toLocaleString('en-US')
      return this.amountFormat(this.taxRate * this.amount)
        .toLocaleString('en-US')
    },

    totalAmount() {
      const fee = this.amountFormat(this.percentFee * this.amount)
      const tax = this.amountFormat(this.taxRate * this.amount)
      const methodFee = this.amountFormat(this.methodFee * this.amount)
      // return parseFloat(this.truncDigits(this.amount + fee + tax)).toLocaleString('en-US')
      return (this.amount + fee + methodFee + tax + this.amountTip).toLocaleString('en-US')
    },

    amountValidate() {
      return !!(this.amount >= this.minTopUp && this.amount <= this.maxAmount)
    },

    maxAmount() {
      return 100000
    },
  },
}
</script>
<style lang="scss" scoped>
.summary-container {
  margin-top: 32px;
  padding: 20px;

  // border: 1px solid rgba(22, 33, 62, 0.1);
  background-color: #F5F5F7;
  border-radius: 10px;

  .title-summary {
    margin-bottom: 21px;
  }
}

.hr-custom {
  border-top: 1px solid #EEEEEE;
}

.total-amount {
  color: #3b4aae;
}
</style>
