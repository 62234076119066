<template>
  <div class="summary-container mt-2">
    <h4
      id="title-summary-tazapay"
      data-testid="title-summary-tazapay"
      class="title-summary"
    >
      {{ $t('addFundPage.creditCardPayment.textSummary') }}
    </h4>
    <hr class="hr-custom">
    <div class="d-flex align-items-center justify-content-between mb-1">
      <div
        id="title-amount-tazapay"
        data-testid="title-amount-tazapay"
        class="font-14 text-placeholder"
      >
        {{ $t('balance.textAmount') }}
      </div>
      <strong
        v-if="amountValidate"
        id="amount-add-fund-tazpay"
        data-testid="amount-add-fund-tazpay"
      >
        {{ localStringFormat(amount) }} USD
      </strong>
      <strong v-else>
        --
      </strong>
    </div>

    <div
      class="d-flex align-items-center justify-content-between mb-1"
    >
      <div
        id="title-tazapay-fee"
        data-testid="title-tazapay-fee"
        class="font-14 text-placeholder"
      >
        {{ $t('addFundPage.creditCardPayment.textPaymentMethod') }}
      </div>
      <div
        v-if="amount"
        class="d-flex align-items-center"
      >
        <img
          :src="getBrandIcon(paymentMethod)"
          alt="payment"
          style="margin-right: 6px;width: 22px"
        >
        <strong
          id="current-tazapay-method"
          data-testid="current-tazapay-method"
          class="text-capitalize"
        >{{ convertNamePayment(paymentMethod) }}</strong>
      </div>
      <strong v-else> -- </strong>
    </div>

    <div
      v-if="percentFee"
      class="d-flex align-items-center justify-content-between mb-1"
    >
      <div
        id="title-service-fee-tazapay"
        data-testid="title-service-fee-tazapay"
        class="font-14 text-placeholder"
      >
        {{ $t('payment.balanceTransactionsTable.textServiceCharge') }}
        <small
          id="percent-service-tazapay-fee"
          data-testid="percent-service-tazapay-fee"
          class="font-small-1"
          style="margin-right: 6px"
        >
          {{ `(${percentFeeTxt})` }}
        </small>
      </div>
      <div>
        <div
          v-if="amountValidate"
          id="service-charge-tazapay"
          data-testid="service-charge-tazapay"
          class="d-flex align-items-center"
        >
          <strong
            id="current-service-charge-tazapay"
            data-testid="current-service-charge-tazapay"
            class="text-primary"
          >{{ serviceCharge }} USD</strong>
        </div>
        <strong v-else>
          --
        </strong>
      </div>
    </div>

    <div
      v-if="methodFee"
      class="d-flex align-items-center justify-content-between mb-1"
    >
      <div
        id="title-tazapay-fee"
        data-testid="title-tazapay-fee"
        class="font-14 text-placeholder"
      >
        {{ $t('payment.paypal.textPaymentFee', {payment: "Tazapay"}) }}
        <small
          id="percent-tazapay-fee"
          data-testid="percent-tazapay-fee"
          class="font-small-1"
          style="margin-right: 6px"
        >
          {{ `(${methodFeeTxt})` }}
        </small>
      </div>
      <div>
        <div
          v-if="amountValidate"
          class="d-flex align-items-center"
        >
          <strong
            id="current-tazapay-fee"
            data-testid="current-tazapay-fee"
            class="text-primary"
          >{{ tazapayFee }} USD</strong>
        </div>
        <strong v-else>
          --
        </strong>
      </div>
    </div>

    <div
      v-if="taxRate"
      class="d-flex align-items-center justify-content-between mb-1"
    >
      <div
        id="title-tax-fee-tazapay"
        data-testid="title-tax-fee-tazapay"
        class="font-14 text-placeholder"
      >
        {{ $t('payment.balanceTransactionsTable.textTax') }}
        <small
          id="percent-tax-tazapay-fee"
          data-testid="percent-tax-tazapay-fee"
          class="font-small-1"
          style="margin-right: 6px"
        >
          {{ `(${percentTaxTxt})` }}
        </small>
      </div>
      <div>
        <div
          v-if="amountValidate"
          class="d-flex align-items-center"
        >
          <strong
            id="current-tax-fee-tazapay"
            data-testid="current-tax-fee-tazapay"
            class="text-primary"
          >{{ tax }} USD</strong>
        </div>
        <strong v-else>
          --
        </strong>
      </div>
    </div>

    <div
      v-if="hasAMId"
      class="d-flex align-items-center justify-content-between"
    >
      <div
        id="title-tip-tazapay"
        data-testid="title-tip-tazapay"
        class="font-14 text-placeholder"
      >
        Tip
        <small
          v-if="currentTip > 0"
          id="percent-tip-tazapay-fee"
          data-testid="percent-tip-tazapay-fee"
          class="font-small-1"
          style="margin-right: 6px"
        >
          {{ `(${percentTipTxt})` }}
        </small>
      </div>
      <div>
        <div
          v-if="amountValidate"
          class="d-flex align-items-center"
        >
          <strong
            id="current-tip-amount-tazapay"
            data-testid="current-tip-amount-tazapay"
            class="text-primary"
          >{{ amountTipTxt }} USD</strong>
        </div>
        <strong v-else>
          --
        </strong>
      </div>
    </div>

    <hr class="hr-custom">
    <div class="d-flex align-items-center justify-content-between pb-3">
      <div
        id="title-total-amount-tazapay"
        data-testid="title-total-amount-tazapay"
        class="font-24 text-placeholder"
      >
        {{ $t('addFundPage.creditCardPayment.textTotalAmount') }}
      </div>
      <strong
        v-if="amountValidate"
        id="current-total-amount-tazapay"
        data-testid="current-total-amount-tazapay"
        class="font-24 total-amount"
      >
        {{ totalAmount }} USD
      </strong>
      <strong v-else>
        --
      </strong>
    </div>
  </div>
</template>

<script>
import paymentMixin from '@/mixins/paymentMixin'
import generalConfigsMixin from '@/mixins/generalConfigsMixin'
import numberFormatMixin from '@/mixins/numberFormatMixin'

// plugins
import { createNamespacedHelpers } from 'vuex'

const { mapGetters: mapGettersAuth } = createNamespacedHelpers('auth')

export default {

  mixins: [paymentMixin, generalConfigsMixin, numberFormatMixin],

  props: {
    amount: {
      type: Number,
      required: true,
    },
    paymentMethod: {
      type: String,
      required: true,
    },
    transfer: {
      type: Object,
      required: true,
    },
    amountTip: {
      type: Number,
      required: true,
    },
    currentTip: {
      type: Number,
      required: true,
    },
  },

  computed: {
    ...mapGettersAuth(['user', 'generalConfigs']),

    isInTrial() {
      return this.user?.isInTrial
    },

    percentFee() {
      return this.user?.data?.plans?.transferFee?.tazapay || 0
    },

    percentFeeTxt() {
      return `${(this.percentFee * 100).toFixed(2)}%`
    },

    methodFee() {
      return this.generalConfigs?.paymentGateFee?.tazapayGate || 0
    },

    methodFeeTxt() {
      return `${(this.methodFee * 100).toFixed(2)}%`
    },

    percentTaxTxt() {
      return `${(this.taxRate * 100).toFixed(2)}%`
    },

    tax() {
      return this.amountFormat(this.taxRate * this.amount).toLocaleString('en-US')
    },

    percentTipTxt() {
      return `${(this.currentTip).toFixed(2)}%`
    },

    amountTipTxt() {
      return this.amountFormat(this.amountTip).toLocaleString('en-US')
    },

    totalAmount() {
      const fee = this.amountFormat(this.percentFee * this.amount)
      const tax = this.amountFormat(this.taxRate * this.amount)
      const methodFee = this.amountFormat(this.methodFee * this.amount)
      return (this.amount + fee + tax + methodFee + this.amountTip).toLocaleString('en-US')
    },

    serviceCharge() {
      return this.amountFormat(this.percentFee * this.amount).toLocaleString('en-US')
    },

    tazapayFee() {
      return this.amountFormat(this.methodFee * this.amount).toLocaleString('en-US')
    },

    amountValidate() {
      return !!(this.amount >= this.minTopUp && this.amount <= this.maxAmount)
    },

    maxAmount() {
      return 100000
    },
  },
}
</script>

<style lang="scss" scoped>
.summary-container {
  padding: 20px;
  background-color: #F5F5F7;
  // border: 1px solid rgba(22, 33, 62, 0.1);
  border-radius: 16px;

  .title-summary{
    margin-bottom: 21px;
  }
}
.transfer {
  max-width: 125px;
}

.total-amount{
  color: #00A9DE;
}
</style>
