<template>
  <div class="summary-container mt-2">
    <h4 class="title-summary">
      {{ $t('SMB.payment.textSummary') }}
    </h4>
    <hr class="hr-custom">
    <div class="d-flex align-items-center justify-content-between mb-1">
      <div class="font-14 text-placeholder">
        {{ $t('SMB.payment.labelAmountInput') }}
      </div>
      <strong
        v-if="amountValidate"
        id="amount"
      >
        {{ localStringFormat(amount) }} USD
      </strong>
      <strong v-else>
        --
      </strong>
    </div>

    <div
      v-if="percentFee"
      class="d-flex align-items-center justify-content-between mb-1"
    >
      <div
        id="title-service-fee-usdt"
        data-testid="title-service-fee-usdt"
        class="font-14 text-placeholder"
      >
        {{ $t('payment.balanceTransactionsTable.textServiceCharge') }}
        <small
          id="percent-service-usdt-fee"
          data-testid="percent-service-usdt-fee"
          class="font-small-1"
          style="margin-right: 6px"
        >
          {{ `(${percentFeeTxt})` }}
        </small>
      </div>
      <div>
        <div
          v-if="amountValidate"
          id="usdt-service-charge"
          data-testid="usdt-service-charge"
          class="d-flex align-items-center"
        >
          <strong
            id="usdt-current-service-charge"
            data-testid="usdt-current-service-charge"
            class="text-primary"
          >{{ serviceCharge }} USD</strong>
        </div>
        <strong v-else>
          --
        </strong>
      </div>
    </div>

    <div
      v-if="methodFee"
      class="d-flex align-items-center justify-content-between mb-1"
    >
      <div
        id="title-lianlian-fee"
        data-testid="title-lianlian-fee"
        class="font-14 text-placeholder"
      >
        {{ $t('payment.paypal.textPaymentFee', { payment: 'LianLian' }) }}
        <small
          id="percent-lianlian-fee"
          data-testid="percent-lianlian-fee"
          class="font-small-1"
          style="margin-right: 6px"
        >
          {{ `(${methodFeeTxt})` }}
        </small>
      </div>
      <div>
        <div
          v-if="amountValidate"
          id="lianlian-fee"
          data-testid="lianlian-fee"
          class="d-flex align-items-center"
        >
          <strong
            id="current-lianlian-fee"
            data-testid="current-lianlian-fee"
            class="text-primary"
          >{{ localBankFee }} USD</strong>
        </div>
        <strong v-else>
          --
        </strong>
      </div>
    </div>

    <div
      v-if="taxRate"
      class="d-flex align-items-center justify-content-between mb-1"
    >
      <div class="font-14 text-placeholder">
        {{ $t('payment.balanceTransactionsTable.textTax') }}
        <small
          class="font-small-1"
          style="margin-right: 6px"
        >
          {{ `(${percentTaxTxt})` }}
        </small>
      </div>
      <div>
        <div
          v-if="amountValidate"
          class="d-flex align-items-center"
        >
          <strong
            id="tax-fee"
            class="text-primary"
          >{{ tax }} USD</strong>
        </div>
        <strong v-else>
          --
        </strong>
      </div>
    </div>

    <div
      class="d-flex align-items-center justify-content-between mb-1"
    >
      <div class="font-14 text-placeholder">
        {{ $t('SMB.payment.paymentMethod') }}
      </div>
      <div
        v-if="amount"
        class="d-flex align-items-center"
      >
        <strong
          id="summary-local-bank-method"
          data-testid="summary-local-bank-method"
          class="d-flex align-items-center font-14 text-primary"
        >
          <img
            :src="logoLocalBank"
            height="16px"
            alt="PayFast"
            style="margin-right: 5px"
          >
          Local bank
        </strong>
      </div>
      <strong v-else> -- </strong>
    </div>

    <hr class="hr-custom">
    <div class="d-flex align-items-center justify-content-between mb-1">
      <div class="font-24 text-placeholder">
        {{ $t('SMB.payment.totalAmount') }}
      </div>
      <strong
        v-if="amountValidate"
        id="total-amount"
        class="font-24 total-amount"
      >
        {{ localStringFormat(totalAmount) }} USD
      </strong>
      <strong v-else>
        --
      </strong>
    </div>

    <div class="d-flex align-items-center justify-content-between mb-3">
      <div class="font-14 text-placeholder">
        {{ $t('SMB.payment.textAmountConvert') }}
      </div>
      <strong
        v-if="amountValidate"
        id="amount"
      >
        {{ localStringFormat(convertUSDtoVND(totalAmount)) }} VNĐ
      </strong>
      <strong v-else>
        --
      </strong>
    </div>
  </div>
</template>

<script>
import paymentMixin from '@/mixins/paymentMixin'
import generalConfigsMixin from '@/mixins/generalConfigsMixin'
import numberFormatMixin from '@/mixins/numberFormatMixin'
import { createNamespacedHelpers } from 'vuex'

const { mapGetters } = createNamespacedHelpers('auth')

export default {

  mixins: [paymentMixin, generalConfigsMixin, numberFormatMixin],

  props: {
    amount: {
      type: Number,
      required: true,
    },
  },

  computed: {
    ...mapGetters(['user']),

    logoLocalBank() {
      // eslint-disable-next-line global-require
      return require('@/assets/images/pages/payment/ic-local-bank.png')
    },

    amountValidate() {
      return !!(this.amount >= this.minTopUp && this.amount <= this.maxAmount)
    },

    maxAmount() {
      return 100000
    },

    taxRate() {
      return this.user?.data?.plans?.transferFee?.tax || 0
    },

    percentTaxTxt() {
      return `${(this.taxRate * 100).toFixed(2)}%`
    },

    percentFee() {
      return this.user?.data?.plans?.transferFee?.localBank || 0
    },

    percentFeeTxt() {
      return `${(this.percentFee * 100).toFixed(2)}%`
    },

    methodFee() {
      return this.generalConfigs?.paymentGateFee?.localBankGate || 0
    },

    methodFeeTxt() {
      return `${(this.methodFee * 100).toFixed(2)}%`
    },

    serviceCharge() {
      return this.amountFormat(this.percentFee * this.amount).toLocaleString('en-US')
    },

    tax() {
      return this.amountFormat(this.taxRate * this.amount)
        .toLocaleString('en-US')
    },

    localBankFee() {
      return this.amountFormat(this.methodFee * this.amount).toLocaleString('en-US')
    },

    totalAmount() {
      const tax = this.amountFormat(this.taxRate * this.amount)
      const serviceFee = this.amountFormat(this.percentFee * this.amount)
      const methodFee = this.amountFormat(this.methodFee * this.amount)
      return (this.amount + tax + serviceFee + methodFee)
    },
  },
}
</script>

<style lang="scss" scoped>
.summary-container {
  padding: 20px;
  background-color: #F5F5F7;
  // border: 1px solid rgba(22, 33, 62, 0.1);
  border-radius: 16px;

  .title-summary{
    margin-bottom: 21px;
  }
}
.transfer {
  max-width: 125px;
}

.total-amount{
  color: #00A9DE;
}
</style>
