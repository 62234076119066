<template>
  <div class="mb-2">
    <USDTIntro
      v-if="showIntro"
      @hide="showIntro = false"
    />
    <div v-else-if="detailInfo">
      <div :class=" hasStepGuide ? 'in-process-step-guide' : 'top-up-in-process'">
        <top-up-success
          class="summary-form"
          :detail-info="detailInfo"
          :amount="detailInfo.amount"
          :transfer="{value: detailInfo.coinNetwork}"
          :coin-type="detailInfo.coinType"
          :payment-method="{value: detailInfo.paymentMethod, icon: detailInfo.coinType}"
        />
      </div>
      <div
        class="user-hint mt-1 mb-2 d-flex"
        :class="hasStepGuide ? '' : 'justify-content-center'"
      >
        <user-hint :class="showUserGuide ? 'd-lg-none' : ''" />
      </div>
    </div>
    <b-row
      v-else-if="!showConfirmation"
      class="width-content add-fund-container mb-2"
    >
      <b-col
        :md="hasStepGuide ? 12 : 9"
        class="p-0 card-content"
      >
        <div
          class="usdt-payment-info p-0"
        >
          <payment-infor
            :method="paymentMethod"
            :transfer="transferNetwork"
            :coin-types="coinTypes"
            :transfer-networks="transferNetworks"
            @on-change="handleChangeMethod"
            @on-change-transfer="handleChangeTransferNetwork"
          />
        </div>

        <div
          class="usdt-top-up-amount mt-2 p-0"
        >
          <!-- :is-toggle="isToggle" -->
          <top-up-amount
            :payment-method="paymentMethod"
            :transfer="transferNetwork"
            :amount="amount"
            :account="account"
            :amount-tip="amountTip"
            :percentage-tip="currentTip"
            :tip-index="currentTipIndex"
            @on-confirm="handleShowConfirm"
          />
        </div>
      </b-col>

      <b-col
        v-if="!hasStepGuide"
        cols="12"
        md="9"
        :lg="hasStepGuide ? 12 : 3"
        class="payment-user-guide card-content"
      >
        <user-guide v-if="showUserGuide" />
      </b-col>
      <div class="user-hint mt-1 mb-2 d-flex">
        <user-hint :class="showUserGuide ? 'd-lg-none' : ''" />
      </div>
    </b-row>
    <b-row
      v-else
      class="width-content add-fund-container"
    >
      <b-col
        :md="hasStepGuide ? 12 : 9"
        class="card-content p-0"
      >
        <!--        <div-->
        <!--          class="usdt-payment-info p-0"-->
        <!--        >-->
        <!--          <payment-infor-->
        <!--            v-if="!isShowSuccessPage"-->
        <!--            :method="paymentMethod"-->
        <!--            :transfer="transferNetwork"-->
        <!--            @on-change="handleChangeMethod"-->
        <!--            @on-change-transfer="handleChangeTransferNetwork"-->
        <!--          />-->
        <!--        </div>-->

        <div
          class="usdt-confirm-amount p-0"
        >
          <!-- :is-toggle="isToggle" -->
          <confirm-amount
            :transaction-info="transactionInfo"
            :amount="amount"
            :account="account"
            :billing="billing"
            :payment-method="paymentMethod"
            :transfer="transferNetwork"
            :amount-tip="amountTip"
            :current-tip="currentTip"
            @on-prev="handlePrev"
            @handleShowSuccess="handleShowSuccess"
          />
        </div>
      </b-col>

      <b-col
        v-if="!hasStepGuide"
        cols="12"
        md="9"
        :lg="hasStepGuide ? 12 : 3"
        class="payment-user-guide card-content"
      >
        <user-guide v-if="showUserGuide" />
      </b-col>
      <div class="user-hint mt-1 mb-2 d-flex">
        <user-hint :class="showUserGuide ? 'd-lg-none' : ''" />
      </div>
    </b-row>
  </div>
</template>

<script>
/* eslint-disable prefer-destructuring */
import { BRow, BCol } from 'bootstrap-vue'
import {
  PAYMENT_METHOD,
} from '@/constants'
import envMixin from '@/mixins/envMixin'
import { createNamespacedHelpers } from 'vuex'
import stepGuideMixin from '@/mixins/stepGuideMixin'
import authMixin from '@/mixins/authMixin'
import UserHint from '@/components/UserHint.vue'
import UserGuide from '@/components/UserGuide.vue'
import paymentMixin from '@/mixins/paymentMixin'
import PaymentInfor from './PaymentInfo.vue'
import TopUpAmount from './TopUpAmount.vue'
import ConfirmAmount from './ConfirmAmount.vue'
import TopUpSuccess from './TopUpSuccess.vue'
import USDTIntro from './USDTIntro.vue'

const { mapGetters } = createNamespacedHelpers('payment')

export default {
  components: {
    BRow,
    BCol,
    PaymentInfor,
    TopUpAmount,
    ConfirmAmount,
    TopUpSuccess,
    USDTIntro,
    UserHint,
    UserGuide,
  },

  mixins: [envMixin, stepGuideMixin, authMixin, paymentMixin],

  data() {
    return {
      paymentMethod: {},
      transferNetwork: {},
      showConfirmation: false,
      amount: 0,
      account: {},
      // isToggle: null,
      transactionInfo: '',
      detailInfo: null,
      billing: [],
      showIntro: false,
      isShowSuccessPage: false,

      contentModal: {},
      usdtPaymentModal: {
        title: 'How to add fund with USDT?',
        description: '',
        video: 'https://www.youtube.com/embed/SiK4v1j-XUM',
        guideURL: process.env.VUE_APP_GUIDE_TO_TOP_UP_USDT,
      },
      usdtPaymentModalNoStep: {
        title: 'How to add fund with USDT?',
        description: '',
        video: 'https://www.youtube.com/embed/ZVEI9F5JQ-o',
        guideURL: process.env.VUE_APP_GUIDE_TO_TOP_UP_USDT,
      },

      currentTip: null,
      amountTip: null,
      currentTipIndex: null,
    }
  },
  computed: {
    ...mapGetters(['listTicket']),

    usdtPaymentInfo() {
      return this.paymentInfo.USDT
    },

    coinTypes() {
      return this.usdtPaymentInfo?.stableCoins?.map(item => ({
        value: item.value,
        name: item.name,
        icon: item.icon,
      }))
    },

    transferNetworks() {
      return this.usdtPaymentInfo?.info?.map(item => ({
        value: item.additionalInfo.usdtChain,
        name: item.additionalInfo.usdtChainName,
        add: item.to,
      }))
    },
    // showIntro() {
    //   return this.isEcomdyPlatform
    // },
  },

  watch: {
    listTicket: {
      handler(val) {
        if (val) {
          const itemDetail = val?.content?.find(item => item?.details?.paymentMethod === PAYMENT_METHOD.USDT && item.status === 'opening')
          this.detailInfo = itemDetail?.details
        }
      },
      deep: true,
      immediate: true,
    },
  },

  created() {
    this.setTransferNetwork()
  },

  methods: {
    setTransferNetwork() {
      this.paymentMethod = this.coinTypes[0]
      this.transferNetwork = this.transferNetworks[0]
    },

    handleChangeMethod(method) {
      this.paymentMethod = method
    },

    handleChangeTransferNetwork(transfer) {
      this.transferNetwork = transfer
    },

    handleShowConfirm(data) {
      this.showConfirmation = true
      this.amount = data.amount
      this.account = data.account
      this.currentTip = data.currentTip
      this.amountTip = data.tipAMAmount
      this.currentTipIndex = data.currentTipIndex
      // this.isToggle = data.isToggle
    },

    handlePrev(data) {
      this.showConfirmation = false
      this.amount = data.amount
      this.transactionInfo = data.transactionId
      this.billing = data.billing
      this.currentTip = data.currentTip
      this.amountTip = data.amountTip
    },

    handleShowSuccess(data) {
      this.isShowSuccessPage = data
      this.$emit('handleShowSuccessTabs', this.isShowSuccessPage)
    },
  },
}
</script>
<style lang="scss">
.horizontal-divide {
  width: 1px;
  height: 100%;
  background: #EEEEEE;
}

.info-box {
  position: absolute;
  top: -23px;
  right: 3px;
  font-size: .875rem;

  .icon-info {
    margin-left: 4px;
  }
}

.mrb-10 {
  margin-bottom: 10px;
}

.top-up-in-process {
  max-width: 410px;
  margin: 0 auto;
  margin-top: 42px;
}

.in-process-step-guide {
  border-radius: 0 0 24px 24px;
  padding: 1.5rem;
  background-color: #fff;
  margin-top: 0;

  .summary-form {
    max-width: 410px;
    margin: 0 auto;
  }
}

.width-content {
  max-width: 1200px;
  margin: 0px auto;

  @media (max-width: 992px) {
    justify-content: center;
  }
}

.guide-video {
  position: absolute;
  top: 19px;
  left: 20px;
  color: #4CB375;
}

.guide-icon {
  top: 19px;
  right: 12px;
}

.usdt-payment-info:hover .tooltip-usdt-payment-info {
  @media (min-width: 992px) {
    display: block;
  }
}

.usdt-top-up-amount:hover .tooltip-usdt-top-up-amount {
  @media (min-width: 992px) {
    display: block;
  }
}

.usdt-confirm-amount:hover .tooltip-usdt-confirm-amount {
  @media (min-width: 992px) {
    display: block;
  }
}

.card-content {
  @media (max-width: 1399px) {
    max-width: 719px;
  }
}
</style>
<style lang="scss" scoped>
.warning-message {
  margin-top: 10px;
  background: rgba(251, 176, 59, 0.1);
  border-radius: 10px;
  padding: 10px 12px 10px 16px;
}
</style>
