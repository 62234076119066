<template>
  <div class="summary-container mt-2">
    <h4
      id="title-summary-payoneer"
      data-testid="title-summary-payoneer"
      class="title-summary"
    >
      {{ $t('addFundPage.creditCardPayment.textSummary') }}
    </h4>
    <hr class="hr-custom">
    <div class="d-flex align-items-center justify-content-between mb-1">
      <div
        id="title-amount-payoneer"
        data-testid="title-amount-payoneer"
        class="font-14 text-placeholder"
      >
        {{ $t('balance.textAmount') }}
      </div>
      <strong
        v-if="amountValidate"
        id="current-summary-amount-po"
        data-testid="current-summary-amount-po"
      >
        {{ localStringFormat(amount) }} USD
      </strong>
      <strong v-else>
        --
      </strong>
    </div>
    <div class="d-flex align-items-center justify-content-between mb-1">
      <div
        id="title-method-payoneer"
        data-testid="title-method-payoneer"
        class="font-14 text-placeholder"
      >
        {{ $t('addFundPage.creditCardPayment.textPaymentMethod') }}
      </div>

      <strong
        id="summary-current-po-method"
        data-testid="summary-current-po-method"
        class="font-14 text-primary"
      >
        <img
          :src="payoneerLogo"
          width="21px"
          alt="Payoneer"
          class="mr-0"
        >
        Payoneer
      </strong>
    </div>

    <div
      v-if="percentFee"
      class="d-flex align-items-center justify-content-between mb-1"
    >
      <div
        id="title-service-charge-payoneer"
        data-testid="title-service-charge-payoneer"
        class="font-14 text-placeholder"
      >
        {{ $t('payment.balanceTransactionsTable.textServiceCharge') }}
        <small
          id="percent-service-po-fee"
          data-testid="percent-service-po-fee"
          class="font-small-1"
          style="margin-right: 6px"
        >
          {{ `(${percentFeeTxt})` }}
        </small>
      </div>
      <div>
        <div
          v-if="amountValidate"
          class="d-flex align-items-center"
        >
          <strong
            id="current-summary-service-charge-po"
            data-testid="current-summary-service-charge-po"
            class="text-primary"
          >{{ serviceCharge }} USD</strong>
        </div>
        <strong v-else>
          --
        </strong>
      </div>
    </div>

    <div
      v-if="methodFee"
      class="d-flex align-items-center justify-content-between mb-1"
    >
      <div
        id="title-method-fee-payoneer"
        data-testid="title-method-fee-payoneer"
        class="font-14 text-placeholder"
      >
        {{ $t('payment.paypal.textPaymentFee', {payment:"Payoneer"}) }}
        <small
          id="percent-po-fee"
          data-testid="percent-po-fee"
          class="font-small-1"
          style="margin-right: 6px"
        >
          {{ `(${methodFeeTxt})` }}
        </small>
      </div>
      <div>
        <div
          v-if="amountValidate"
          class="d-flex align-items-center"
        >
          <strong
            id="summary-current-po-fee"
            data-testid="summary-current-po-fee"
            class="text-primary"
          >{{ payoneerFee }} USD</strong>
        </div>
        <strong v-else>
          --
        </strong>
      </div>
    </div>

    <div
      v-if="taxRate"
      class="d-flex align-items-center justify-content-between mb-1"
    >
      <div
        id="title-tax-fee-payoneer"
        data-testid="title-tax-fee-payoneer"
        class="font-14 text-placeholder"
      >
        {{ $t('payment.balanceTransactionsTable.textTax') }}
        <small
          id="percent-tax-po-fee"
          data-testid="percent-tax-po-fee"
          class="font-small-1"
          style="margin-right: 6px"
        >
          {{ `(${percentTaxTxt})` }}
        </small>
      </div>
      <div>
        <div
          v-if="amountValidate"
          id="summary-current-tax-po-fee"
          data-testid="summary-current-tax-po-fee"
          class="d-flex align-items-center"
        >
          <strong
            id="current-tax-po-fee"
            data-testid="current-tax-po-fee"
            class="text-primary"
          >{{ tax }} USD</strong>
        </div>
        <strong v-else>
          --
        </strong>
      </div>
    </div>

    <div
      v-if="hasAMId"
      class="d-flex align-items-center justify-content-between"
    >
      <div
        id="title-tip-payoneer"
        data-testid="title-tip-payoneer"
        class="font-14 text-placeholder"
      >
        Tip
        <small
          v-if="currentTip > 0"
          id="percent-tip-id-po"
          data-testid="percent-tip-id-po"
          class="font-small-1"
          style="margin-right: 6px"
        >
          {{ `(${percentTipTxt})` }}
        </small>
      </div>
      <div>
        <div
          v-if="amountValidate"
          id="summary-current-tip-amount-po"
          data-testid="summary-current-tip-amount-po"
          class="d-flex align-items-center"
        >
          <strong
            id="current-tip-amount-po"
            data-testid="current-tip-amount-po"
            class="text-primary"
          >{{ amountTipTxt }} USD</strong>
        </div>
        <strong v-else>
          --
        </strong>
      </div>
    </div>

    <hr class="hr-custom">

    <div class="d-flex align-items-center justify-content-between pb-3">
      <div
        id="title-total-amount-payoneer"
        data-testid="title-total-amount-payoneer"
        class="font-24 text-placeholder"
      >
        {{ $t('addFundPage.creditCardPayment.textTotalAmount') }}
      </div>
      <strong
        v-if="amountValidate"
        id="current-total-amount-po"
        date-testid="current-total-amount-po"
        class="font-24 total-amount"
      >
        {{ totalAmount }} USD
      </strong>
      <strong v-else>
        --
      </strong>
    </div>
  </div>
</template>
<script>
import { createNamespacedHelpers } from 'vuex'

// Mixins
import paymentMixin from '@/mixins/paymentMixin'
import numberFormatMixin from '@/mixins/numberFormatMixin'
import generalConfigsMixin from '@/mixins/generalConfigsMixin'
import { toastification } from '@core/mixins/toast'

const { mapGetters: mapGettersAuth } = createNamespacedHelpers('auth')

export default {

  mixins: [paymentMixin, toastification, numberFormatMixin, generalConfigsMixin],

  props: {
    amount: {
      type: Number,
      required: true,
    },

    amountTip: {
      type: Number,
      required: true,
    },
    currentTip: {
      type: Number,
      required: true,
    },
  },

  computed: {
    ...mapGettersAuth(['user']),

    isInTrial() {
      return this.user?.isInTrial
    },

    payoneerLogo() {
      // eslint-disable-next-line global-require
      return require('@/assets/images/pages/payment/payoneer.svg')
    },

    percentFee() {
      return this.user?.data?.plans?.transferFee?.payoneer || 0
    },

    percentFeeTxt() {
      return `${(this.percentFee * 100).toFixed(2)}%`
    },

    percentTaxTxt() {
      return `${(this.taxRate * 100).toFixed(2)}%`
    },

    methodFee() {
      return this.generalConfigs?.paymentGateFee?.payoneerGate || 0
    },

    methodFeeTxt() {
      return `${(this.methodFee * 100).toFixed(2)}%`
    },

    payoneerFee() {
      return this.amountFormat(this.methodFee * this.amount).toLocaleString('en-US')
    },

    serviceCharge() {
      // return parseFloat(this.truncDigits(this.percentFee * this.amount)).toLocaleString('en-US')
      return this.amountFormat(this.percentFee * this.amount).toLocaleString('en-US')
    },

    tax() {
      // return parseFloat(this.truncDigits(this.taxRate * this.amount)).toLocaleString('en-US')
      return this.amountFormat(this.taxRate * this.amount).toLocaleString('en-US')
    },

    percentTipTxt() {
      return `${(this.currentTip).toFixed(2)}%`
    },

    amountTipTxt() {
      return this.amountFormat(this.amountTip).toLocaleString('en-US')
    },

    totalAmount() {
      const fee = this.amountFormat(this.percentFee * this.amount)
      const tax = this.amountFormat(this.taxRate * this.amount)
      const methodFee = this.amountFormat(this.methodFee * this.amount)
      // return parseFloat(this.truncDigits(this.amount + fee + tax)).toLocaleString('en-US')
      return (this.amount + fee + tax + methodFee + this.amountTip).toLocaleString('en-US')
    },

    amountValidate() {
      return !!(this.amount >= this.minTopUp && this.amount <= this.maxAmount)
    },

    maxAmount() {
      return 100000
    },
  },

  methods: {

  },
}
</script>
<style lang="scss" scoped>
.summary-container {
  padding: 20px;
  background-color: #F5F5F7;
  // border: 1px solid rgba(22, 33, 62, 0.1);
  border-radius: 16px;

  .title-summary{
    margin-bottom: 21px;
  }
}
  .hr-custom{
    border-top: 1px solid #EEEEEE;
  }

  .total-amount{
    color: #ff4901;
  }
</style>
