<template>
  <div class="text-center">
    <div class="header px-2 pb-1">
      <img
        alt="success"
        width="66px"
        :src="topUpInprocessImg"
      >
      <h3
        class="my-1"
        data-testid="top-up-in-process-title"
      >
        {{ $t('payment.wiseTransfer.titleTopupInprocess') }}
      </h3>
      <p>{{ $t('payment.wiseTransfer.subTitleTopupInprocess') }}</p>
    </div>
    <div class="summary p-2">
      <h4
        id="title-summary-usdt"
        data-testid="title-summary-usdt"
        class="text-left mb-2"
      >
        {{ $t('addFundPage.creditCardPayment.textSummary') }}
      </h4>
      <div class="d-flex align-items-center justify-content-between mb-1 flex-wrap">
        <div
          id="summary-usdt-title-client"
          data-testid="summary-usdt-title-client"
          class="font-14 text-placeholder"
        >
          {{ $t('addFundPage.creditCardPayment.textClient') }}
        </div>
        <strong
          id="summary-usdt-client-name"
          data-testid="summary-usdt-client-name"
        >
          {{ fullName }}
        </strong>
      </div>
      <div class="d-flex align-items-center justify-content-between mb-1">
        <div
          id="summary-usdt-title-amount"
          data-testid="summary-usdt-title-amount"
          class="font-14 text-placeholder"
        >
          {{ $t('balance.textAmount') }}
        </div>
        <strong
          id="summary-usdt-current-amount"
          data-testid="summary-usdt-current-amount"
        >
          {{ localStringFormat(amount) }} USD
        </strong>
      </div>
      <div class="d-flex align-items-center justify-content-between mb-1">
        <div
          id="summary-usdt-title-method"
          data-testid="summary-usdt-title-method"
          class="font-14 text-placeholder"
        >
          {{ $t('addFundPage.creditCardPayment.textPaymentMethod') }}
        </div>
        <div
          id="summary-usdt-content-method"
          data-testid="summary-usdt-content-method"
        >
          <img
            id="summary-usdt-icon-method"
            data-testid="summary-usdt-icon-method"
            :src="getBrandIcon(`USDT`)"
            width="18px"
            alt="coin"
            style="margin-right: 6px"
          >
          <strong
            id="summary-usdt-current-method"
            data-testid="summary-usdt-current-method"
          >USDT</strong>
        </div>
      </div>

      <div class="d-flex align-items-center justify-content-between mb-1">
        <div
          id="summary-usdt-title-coin-type"
          data-testid="summary-usdt-title-coin-type"
          class="font-14 text-placeholder"
        >
          {{ $t('payment.USDT.labelCoinType') }}
        </div>
        <div v-if="amount">
          <img
            id="summary-usdt-icon-coin-type"
            data-testid="summary-usdt-icon-coin-type"
            :src="getBrandIcon(coinType)"
            width="18px"
            alt="coin"
            style="margin-right: 6px"
          >
          <strong
            id="summary-usdt-current-coin-type"
            data-testid="summary-usdt-current-coin-type"
          >{{ coinType }}</strong>
        </div>
        <strong v-else>
          --
        </strong>
      </div>
      <div
        v-if="percentFee || (detailInfo && detailInfo.serviceCharge)"
        class="d-flex align-items-center justify-content-between mb-1"
      >
        <div
          id="summary-usdt-title-service-charge"
          data-testid="summary-usdt-title-service-charge"
          class="font-14 text-placeholder"
        >
          {{ $t('payment.balanceTransactionsTable.textServiceCharge') }}
        </div>
        <strong
          id="summary-usdt-current-service-charge"
          data-testid="summary-usdt-current-service-charge"
          class="theme-text-primary"
        >{{ serviceCharge }} USD</strong>
      </div>

      <div
        v-if="methodFee || (detailInfo && detailInfo.paymentGateCharge)"
        class="d-flex align-items-center justify-content-between mb-1"
      >
        <div
          id="summary-usdt-title-method-fee"
          data-testid="summary-usdt-title-method-fee"
          class="font-14 text-placeholder"
        >
          {{ $t('payment.paypal.textPaymentFee', { payment: 'USDT' }) }}
        </div>
        <div>
          <div
            class="d-flex align-items-center"
          >
            <strong
              id="summary-usdt-current-method-fee"
              data-testid="summary-usdt-current-method-fee"
              class="text-primary"
            >{{ USDTFee }} USD</strong>
          </div>
        </div>
      </div>

      <div
        v-if="taxRate || (detailInfo && detailInfo.taxCharge)"
        class="d-flex align-items-center justify-content-between mb-1"
      >
        <div
          id="summary-usdt-title-tax-fee"
          data-testid="summary-usdt-title-tax-fee"
          class="font-14 text-placeholder"
        >
          {{ $t('payment.balanceTransactionsTable.textTax') }}
        </div>
        <div>
          <strong
            id="summary-usdt-current-tax-fee"
            data-testid="summary-usdt-current-tax-fee"
            class="theme-text-primary"
          >{{ tax }} USD</strong>
        </div>
      </div>

      <div class="d-flex align-items-center justify-content-between mb-1">
        <div
          id="summary-usdt-title-transfer"
          data-testid="summary-usdt-title-transfer"
          class="font-14 text-placeholder"
        >
          {{ $t('payment.USDT.labelTransferNetwork') }}
        </div>
        <div class="transfer">
          <div
            v-if="transfer.value"
            class="d-flex align-items-center text-transfer"
          >
            <strong
              id="summary-usdt--current-transfer-network"
              data-testid="summary-usdt--current-transfer-network"
              class="theme-text-primary"
            >{{ transfer.value }}</strong>
          </div>
        </div>
      </div>

      <div
        v-if="tipAmount > 0"
        class="d-flex align-items-center justify-content-between mb-1"
      >
        <div
          id="summary-usdt-title-tip-amount"
          data-testid="summary-usdt-title-tip-amount"
          class="font-14 text-placeholder"
        >
          Tip
        </div>
        <strong
          id="summary-usdt-current-tip-amount"
          data-testid="summary-usdt-current-tip-amount"
          class="text-primary"
        >{{ tipAmountTxt }} USD</strong>
      </div>

      <hr class="hr-custom">

      <div class="d-flex align-items-center justify-content-between">
        <div
          id="summary-usdt-title-total-amount"
          data-testid="summary-usdt-title-total-amount"
          class="font-14 text-placeholder text-left"
        >
          {{ $t('addFundPage.creditCardPayment.textTotalAmount') }} = {{ $t('balance.textAmount') }}
          {{ percentFee || (detailInfo && detailInfo.serviceCharge) ? ` + ${$t('payment.balanceTransactionsTable.textServiceCharge')}` : '' }}
          {{ taxRate || (detailInfo && detailInfo.taxCharge) ? ` + ${$t('payment.balanceTransactionsTable.textTax')}` : '' }}
          {{ methodFee || (detailInfo && detailInfo.paymentGateCharge) ? ` + ${$t('payment.paypal.textPaymentFee', { payment: 'USDT' })}` : '' }}
          {{ tipAmount > 0 ? ' + Tip' : '' }}
        </div>
      </div>
      <h3
        id="summary-usdt-current-total-amount"
        data-testid="summary-usdt-current-total-amount"
        class="mt-2 text-left"
      >
        {{ totalAmount }} USD
      </h3>
    </div>
  </div>
</template>
<script>
import { createNamespacedHelpers } from 'vuex'
import _get from 'lodash/get'
import { bus } from '@/plugins/bus'

import numberFormatMixin from '@/mixins/numberFormatMixin'
import paymentMixin from '@/mixins/paymentMixin'
import generalConfigsMixin from '@/mixins/generalConfigsMixin'
import stepGuideMixin from '@/mixins/stepGuideMixin'
import { PAYMENT_METHOD } from '@/constants'

const { mapGetters } = createNamespacedHelpers('payment')
const { mapGetters: mapGettersAuth } = createNamespacedHelpers('auth')

export default {
  mixins: [numberFormatMixin, generalConfigsMixin, paymentMixin, stepGuideMixin],
  props: {
    paymentMethod: {
      type: Object,
      required: true,
    },

    detailInfo: {
      type: Object,
      required: false,
      default: () => {
      },
    },

    transfer: {
      type: Object,
      required: true,
    },

    amount: {
      type: Number,
      required: true,
    },
    coinType: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      historyTicket: {},
    }
  },

  computed: {
    ...mapGetters(['listCard', 'loading', 'listTicket']),
    ...mapGettersAuth(['user']),

    isShowBtnBackToDemoView() {
      return this.hasStepGuide && this.user?.data?.balance > 0
    },

    fullName() {
      const fullName = _get(this.user, ['data', 'fullName'], '')
      const email = _get(this.user, ['data', 'email'], '')
      return fullName || email
    },

    percentFee() {
      return this.user?.data?.plans?.transferFee?.USDT || 0
    },

    serviceCharge() {
      if (!this.detailInfo) {
        return this.amountFormat(this.percentFee * this.amount)
          .toLocaleString('en-US')
      }

      if (this.detailInfo?.serviceCharge) {
        return this.detailInfo?.serviceCharge
      }

      return 0
    },

    methodFee() {
      return this.generalConfigs?.paymentGateFee?.USDTGate || 0
    },

    methodFeeTxt() {
      return `${(this.methodFee * 100).toFixed(2)}%`
    },

    USDTFee() {
      if (!this.detailInfo) {
        return this.amountFormat(this.methodFee * this.amount)
          .toLocaleString('en-US')
      }
      if (this.detailInfo?.paymentGateCharge) {
        return this.detailInfo?.paymentGateCharge
      }
      return 0
    },

    tipAmount() {
      return this.historyTicket?.details?.tipAMAmount || null
    },

    tipAmountTxt() {
      return this.amountFormat(this.tipAmount)
        .toLocaleString('en-US')
    },

    totalAmount() {
      if (!this.detailInfo) {
        const fee = this.amountFormat(this.percentFee * this.amount)
        const tax = this.amountFormat(this.taxRate * this.amount)
        const methodFee = this.amountFormat(this.methodFee * this.amount)
        return (this.amount + fee + tax + methodFee + this.tipAmount).toLocaleString('en-US')
      }

      const {
        serviceCharge,
        taxCharge,
        paymentGateCharge,
      } = this.detailInfo

      return (this.amount + serviceCharge + taxCharge + paymentGateCharge + this.tipAmount).toLocaleString('en-US')
    },

    percentTaxTxt() {
      return `${(this.taxRate * 100).toFixed(2)}%`
    },

    tax() {
      if (!this.detailInfo) {
        return this.amountFormat(this.taxRate * this.amount)
          .toLocaleString('en-US')
      }

      if (this.detailInfo?.taxCharge) {
        return this.detailInfo?.taxCharge
      }

      return 0
    },
  },

  watch: {
    listTicket: {
      handler(ticket) {
        if (ticket.paging.total > 0) {
          this.historyTicket = ticket?.content?.find(transaction => transaction.details.paymentMethod === PAYMENT_METHOD.USDT)
        }
      },
      deep: true,
      immediate: true,
    },
  },

  created() {
    bus.$on('set-invoice-data', data => {
      this.amount = data.amount
    })
  },
}
</script>
<style lang="scss" scoped>
.hr-custom {
  border-top: 1px solid #EEEEEE;
}

.summary {
  border-radius: var(--border-radius-base);
  box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
}
</style>
