<template>
  <div>
    <div
      v-show="showErrorPage"
      class="mx-auto success-page mt-3 mb-1"
    >
      <top-up-failure
        :show-error-page.sync="showErrorPage"
        :payment-method="PAYMENT_METHOD.PAYPAL"
      />
    </div>

    <!-- success page -->
    <div
      v-show="showSuccessPage"
      class="mx-auto success-page mt-3 mb-1"
    >
      <top-up-success
        :show-success-page.sync="showSuccessPage"
        :payment-method="PAYMENT_METHOD.PAYPAL"
        @handle-recharge-paypal="showSuccessPage = false"
      />
    </div>
    <!-- /end success page -->
    <b-row
      v-show="!showSuccessPage && !showErrorPage"
      class="width-content add-fund-container"
    >
      <b-col
        :md="!hasStepGuide ? 9 : 12"
        data-testid="paypal-top-up-form"
        class="p-0 top-up-form top-up-form-paypal"
      >
        <div
          class="bg-white"
          :class="hasStepGuide ? 'radius-content' : 'list-card'"
        >
          <top-up-form
            ref="top-up-form-pay-pal"
            :user-data="user"
            @top-up-status-handler="topUpStatusHandler"
          />
        </div>
        <div
          class="user-hint mt-1 mb-2 d-flex"
        >
          <user-hint :class="showUserGuide ? 'd-lg-none' : ''" />
        </div>
      </b-col>
      <!-- TopUp Form -->
      <b-col
        v-if="!hasStepGuide"
        data-testid="paypal-user-hint"
        cols="12"
        md="9"
        :lg="hasStepGuide ? 12 : 3"
        class="payment-user-guide card-content"
      >
        <user-guide v-if="showUserGuide" />
      </b-col>
    </b-row>

    <FirstTopUpSuccessModal v-if="isPancakeUser && showSuccessPage && pancakeUserStep" />
  </div>
</template>
<script>
/* eslint-disable global-require */

import { BRow, BCol } from 'bootstrap-vue'
import { createNamespacedHelpers } from 'vuex'
import { toastification } from '@core/mixins/toast'
import paymentMixin from '@/mixins/paymentMixin'
import gaTrackingMixin from '@/mixins/gaTrackingMixin'
import { PAYMENT_METHOD, TRANSACTION_TYPE } from '@/constants'
import stepGuideMixin from '@/mixins/stepGuideMixin'
import authMixin from '@/mixins/authMixin'
import envMixin from '@/mixins/envMixin'
import UserHint from '@/components/UserHint.vue'
import UserGuide from '@/components/UserGuide.vue'
import FirstTopUpSuccessModal from '@/views/payment/components/FirstTopUpSuccessModal.vue'
import TopUpForm from './TopUpForm.vue'
import TopUpSuccess from '../CreditCard/TopUpSuccess.vue'
import TopUpFailure from './TopUpFailure.vue'

const { mapActions } = createNamespacedHelpers('payment')
const { mapGetters: mapGettersAuth, mapActions: mapActionsAuth } = createNamespacedHelpers('auth')

export default {
  components: {
    TopUpFailure,
    TopUpSuccess,
    TopUpForm,
    UserHint,
    UserGuide,
    BRow,
    BCol,
    FirstTopUpSuccessModal,
  },
  mixins: [toastification, paymentMixin, stepGuideMixin, authMixin, gaTrackingMixin, envMixin],
  data() {
    return {
      showSuccessPage: false,
      showErrorPage: false,
      pancakeUserStep: localStorage.getItem('pancakeUserStep') === '3',
      // const
      PAYMENT_METHOD,

      paypalPaymentModal: {
        title: 'How to add fund with PayPal?',
        description: '',
        video: 'https://www.youtube.com/embed/Y57UJcVQKLY',
        guideURL: process.env.VUE_APP_GUIDE_TO_TOP_UP_PAYPAL,
      },

      paypalPaymentModalNoStep: {
        title: 'How to add fund with PayPal?',
        description: '',
        video: 'https://www.youtube.com/embed/9mkpZE85Sgw',
        guideURL: process.env.VUE_APP_GUIDE_TO_TOP_UP_PAYPAL,
      },
    }
  },

  computed: {
    ...mapGettersAuth(['user']),
  },

  methods: {
    ...mapActions(['getHistoryLimit']),
    ...mapActionsAuth(['getAccountInfo']),

    topUpStatusHandler(status) {
      if (status) {
        this.handleSuccess()
      } else {
        this.handleError()
      }
    },

    async handleSuccess() {
      this.fetchHistory()
      this.getAccountInfo() // for date user balance
      await this.$refs['top-up-form-pay-pal'].sendInvoice() // send data to success page.
      this.$refs['top-up-form-pay-pal'].resetForm() // reset top up form

      this.toastSuccess(this.$t('payment.toastTopUpSuccess'))
      if (this.hasStepGuide) {
        if (this.isOnlyEcomdyPlatform) {
          this.gaTrackingUserStep(this.setupGuideStep) // GA Tracking
        }
        this.$router.push({ name: 'my-ads-account' })
      } else {
        this.showSuccessPage = true

        this.$emit('handleShowSuccessTabs', this.showSuccessPage)
      }
    },

    async handleError() {
      await this.$refs['top-up-form-pay-pal'].sendInvoice() // send data to success page.
      this.$refs['top-up-form-pay-pal'].resetForm() // reset top up form
      this.showErrorPage = true
    },

    async fetchHistory() {
      const params = {
        page: 1,
        size: 1,
        types: [TRANSACTION_TYPE.RECHARGE_USER_BALANCE, TRANSACTION_TYPE.ADMIN_FIX_RECHARGE_MONEY],
      }
      await this.getHistoryLimit(params)
    },
  },
}
</script>
<style lang="scss" scoped>
@import "@/assets/scss/variables/_variables.scss";

    .hr-custom{
      border-top: 1px solid rgba(22, 33, 62, 0.1);
    }

  .list-card{
    // max-width: 864px;
    border-radius: var(--border-radius-base);
  }

  .radius-content {
    border-radius: 0px 0px var(--border-radius-base) var(--border-radius-base);
  }

  .top-up-form {
    // max-width: 864px;
    border-radius: 14px;

    @media (max-width: 1399px) {
      max-width: 719px;
    }
  }

  .success-page{
    max-width: 410px;
  }

  .width-content {
    max-width: 1200px;
    margin: 0px auto;

    @media (max-width: 992px){
    justify-content: center;
  }
  }

  // .top-up-form-paypal:hover .tooltip-top-up-form-paypal {
  //   display: block;
  // }
  .guide-icon {
    top: 19px;
    right: 12px;
  }

  .top-up-form-paypal:hover .tooltip-top-up-form-paypal {
    @media (min-width: 992px) {
      display: block;
    }
  }

</style>
